<template>
    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 order-clg-1">
        <div v-html="page"></div>
        <div class="education__detail-description-list-title">
            Стоимость обучения*
        </div>
        <div class="education__detail-description">
            <div class="education__detail-table">
                <div class="education__detail-table-item">Формат обучения</div>
                <div class="education__detail-table-item">
                    {{
                        this.typePrise === '1'
                            ? 'Стоимость за услугу* (руб.)'
                            : 'Стоимость за участника* (руб.)'
                    }}
                </div>
            </div>
            <div
                class="education__detail-table"
                v-for="(item, id) in coursePrice"
                :key="id"
            >
                <div class="education__detail-table-item">
                    {{ item.format_title }}
                </div>
                <div class="education__detail-table-item">
                    {{
                        String(item.price).replace(
                            /(\d)(?=(\d\d\d)+([^\d]|$))/g,
                            '$1 '
                        )
                    }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['page', 'coursePrice', 'typePrise'],
    };
</script>

<style lang="sass" scoped>
    .education__detail-table
      color: #000000
      background-color: #EFEFEF
      max-width: 544px
      width: 100%
      display: flex
      padding: 8px 12px 8px 8px
      border: 1px solid #fff

      &-item
        width: 50%
        padding: 0 5px

      &:nth-child(2)
        background: #F4F4F4
</style>
