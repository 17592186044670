<template>
    <div class="container">
        <!-- title -->
        <div class="row">
            <div class="container container--out-pad container--add-pad">
                <div class="education__detail">
                    <div class="education__detail-title">{{ page.title }}</div>
                    <!--<div class="education__detail-description">
            Краткое описание программы
          </div>-->
                    <div class="education__detail-description-text-title">
                        {{ page.description }}
                    </div>
                    <div class="row">
                        <DetailPageLeft
                            :page="page.content_main"
                            :courseType="page.courseType.name"
                            :type="name"
                            :courseId="page.url"
                        />
                        <DetailPageRight
                            :page="page.content_program"
                            :typePrise="page.is_full_price"
                            :coursePrice="page.coursePrice"
                        />
                    </div>
                    <div
                        class="education__detail-footer-description"
                        v-if="page.additional_description"
                    >
                        <div class="education__detail-footer-description-title">
                            Дополнительно
                        </div>
                        <div class="education__detail-footer-description-text">
                            {{ page.additional_description }}
                        </div>
                    </div>
                    <div class="education__detail-footer-description" v-else>
                        <div class="education__detail-footer-description-title">
                            Дополнительно
                        </div>
                        <div class="education__detail-footer-description-text">
                            *Стоимость указана без учёта НДС. Компания Digital
                            Concept НДС не облагается, в&nbsp;связи
                            с&nbsp;применением упрощённой системы
                            налогообложения.
                            <p>
                                **Дополнительные расходы, связанные
                                с&nbsp;перелетом и проживанием участников,
                                оплачиваются дополнительно (при проведении
                                очного сборного тренинга). Дополнительные
                                расходы по&nbsp;организации очного обучения
                                (аренда аудитории и&nbsp;оборудования, питание
                                для участников) оплачиваются дополнительно.
                                Дополнительные расходы, связанные
                                с&nbsp;командировочными затратами
                                на&nbsp;перелет и&nbsp;проживание тренера
                                оплачиваются дополнительно (при проведении
                                очного тренинга в&nbsp;городе Заказчика,
                                за&nbsp;исключением Санкт-Петербурга).
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import DetailPageLeft from '@/components/education/DetailPageLeft';
    import DetailPageRight from '@/components/education/DtailPageRight';
    import { get } from '@/lib/axios';
    export default {
        components: { DetailPageLeft, DetailPageRight },
        data() {
            return {
                page: '',
                name: '',
            };
        },
        methods: {
            async fetchPage() {
                await get(`/education/course/${this.$route.params.id}`)
                    .then(response => {
                        this.page = response.data;
                        this.name = response.courseType.name;
                    })
                    .catch(e => {
                        console.log(e);
                    });
            },
        },
        mounted() {
            this.fetchPage();
        },
        metaInfo() {
            return {
                title: `Обучение - ЛУКМАРКЕТ`,
            };
        },
    };
</script>

<style lang="sass">

    .education__detail
        padding: 60px 0
        &-title
            font-size: 24px
            line-height: 25px
            font-weight: 700
            max-width: 700px
            width: 100%

        &-footer-description
            padding: 20px
            margin: 40px 0
            background: #F7F7F9

            &-title
                color: #D2233C
                font-size: 14px
                font-weight: 400
                line-height: 14.74px

            &-text
                font-weight: 400
                font-size: 12px
                line-height: 12.64px
                padding-top: 12px

        &-description
            color: #4A4A5A
            font-size: 16px
            line-height: 17px
            margin: 20px 0

            &-text
                font-size: 18px
                font-weight: 400
                line-height: 19px
                color: #000000
                margin: 4px 0 0 0
                max-width: 511px
                width: 100%

                &-title
                  font-size: 18px
                  font-weight: 400
                  line-height: 19px
                  color: #000000
                  margin: 32px 0 0 0
                  max-width: 511px
                  width: 100%

            &-ul
                margin: 20px -20px

                &-item
                    font-size: 18px
                    font-weight: 400
                    line-height: 19px
                    color: #000
                    margin: 12px 0

            &-list
                display: flex
                flex-direction: row
                align-items: center

                &-content
                    margin: 20px 0 16px 0
                    font-weight: 700
                    color: #000
                    font-size: 18px
                    line-height: 19px

                &-title
                    margin: 40px 0 16px 0
                    font-weight: 700
                    color: #D2233C
                    font-size: 18px
                    line-height: 19px

                &-icon
                    background-image: url('../../../assets/images/education_icon.svg')
                    width: 20px
                    height: 20px
                    position: absolute

                &-text
                    padding: 12px 30px
                    color: #000
                    font-weight: 400
                    font-size: 18px
                    line-height: 19px

        &-gallery
              display: flex
              flex-direction: row
              max-width: 534px
              width: 100%
              align-items: center
              &-img
                  margin: 0 29px 0px 0
                  cursor: pointer
                  display: flex
                  justify-content: center
                  align-items: center
                  img
                      width: 100%
              &-icon
                  position: relative
                  left: -50%
                  width: 21px
                  height: 18px
</style>
