var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-xl-6 col-lg-6 col-md-6 order-clg-2"},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.page)}}),(
            _vm.courseType !== 'technical' &&
            _vm.courseType !== 'supervisorNsto' &&
            _vm.courseType !== 'masterEducation'
        )?_c('div',[_c('div',{staticClass:"education__detail-description-list-title"},[_vm._v(" Образцы сертификатов ")]),_vm._m(0),_c('div',{staticClass:"education__detail-gallery"},_vm._l((_vm.image),function(image,key){return _c('div',{key:key,staticClass:"education__detail-gallery-img"},[_c('img',{staticClass:"item",attrs:{"alt":"","src":image},on:{"click":function($event){_vm.selectImage(image, key);
                        _vm.show();}}}),_c('img',{staticClass:"education__detail-gallery-icon",attrs:{"src":require("../../assets/images/lupa.svg")},on:{"click":function($event){_vm.selectImage(image, key);
                        _vm.show();}}})])}),0)]):_vm._e(),_c('div',{staticClass:"education__button"},[_c('router-link',{staticClass:"education__button-btn",attrs:{"custom":"","to":{
                name: 'Education_request',
                params: {
                    id: _vm.courseId,
                },
            }}},[_vm._v(" ОФОРМИТЬ ЗАЯВКУ ")])],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"education__detail-description"},[_c('div',{staticClass:"education__detail-description-text"},[_vm._v(" Участники проходят входное и итоговое тестирование. По итогам тестирования компании-дилеру присваивается грейд и выдается сертификат: "),_c('div',{staticClass:"mt-3"},[_vm._v("1 уровень – начальный")]),_c('div',[_vm._v("2 уровень – базовый")]),_c('div',[_vm._v("3 уровень – продвинутый")])])])}]

export { render, staticRenderFns }